/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../../App.styled';
import { BsWhatsapp, BsInstagram, BsYoutube, BsLinkedin } from 'react-icons/bs';
import { ButtonNavigateToTop,
        DocumentosRegulatorios,
        Modal,
        Parceiros,
        Equipe } from '..';

import { useResize } from '../../hooks';
import {
  ContainerFull,
  Container,
  LogoWrapper,
  LogoContainer,
  DivContactInfos,
  DivParagraph,
  LogosContainer,
  Address,
  ContentTop,
  MenuWrapper,
  Title,
  SocialMedia,
  LogoCVM,
  LogoCFP
} from './index.styled';

const FooterComponent = ({ themeType }) => {
  const { bsBreakPoint } = useResize();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEquipeOpen, setIsModalEquipeOpen] = useState(false);


  const toggleModal = (event) => {
    event.preventDefault();
    setIsModalOpen(!isModalOpen);
  }

  const toggleModalEquipe = (event) => {
    event.preventDefault();
    setIsModalEquipeOpen(!isModalEquipeOpen);
  }

  // eslint-disable-next-line
  const canRender = () => {
    if (
      bsBreakPoint !== 'xs' &&
      bsBreakPoint !== 'sm' &&
      bsBreakPoint !== 'md'
    ) {
      return true;
    }
    return false;
  };

  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  const navigateTo = (url) => {
    window.open(url, '_blank');
  }

  const navigateToTop = e => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };
  return (
    <ThemeProvider theme={{ type: theme[themeType] }}>
      <Modal isOpen={isModalOpen} toggleModal={() => setIsModalOpen(!isModalOpen)} isClickOutside={true}>
         <Parceiros />
      </Modal>
      <Modal isOpen={isModalEquipeOpen} toggleModal={() => setIsModalEquipeOpen(!isModalEquipeOpen)} isClickOutside={true}>
         <Equipe />
      </Modal>
      <ContainerFull className="separator-bootom">
        <Container distance column justifyCenter>
          <ContentTop>
            <LogoWrapper>
              <Link to="/" onClick={navigateToTop}>
                <LogoContainer />
              </Link>
              <Address style={{cursor: 'pointer'}} onClick={() => navigateTo('https://goo.gl/maps/UQocpAeAgVFEGRay6')}>
                Av. Deputado Jamel Cecílio, 2690<br />
                Jardim Goiás, Goiânia - GO
              </Address>
            </LogoWrapper>
            <MenuWrapper>
              <Title>Institucional</Title>
              <Link to="/sobre-nos">Sobre nós</Link>
              <a href="#" onClick={toggleModalEquipe}>Equipe</a>
              <a href="#" onClick={toggleModal}>Parcerias</a>
              <a href="mailto:contato@yunofp.com.br">Trabalhe conosco</a>
            </MenuWrapper>
            <MenuWrapper>
              <Title>Serviços</Title>
              <Link to="/para-voce">Planejamento Financeiro Pessoal</Link>
              <a href="https://app.realcf.com.br/" target="_blank">Yuno On</a>
              <a href="https://yunoknow.braipmembers.com" target="_blank">Yuno Know</a>
            </MenuWrapper>
            <MenuWrapper>
              <Title>Entre em contato</Title>
              <Link to="#" onClick={() => gotoWhatsApp(5562993376742)}><BsWhatsapp className="whatsapp" />Marque aqui sua reunião</Link>
              <a href="mailto:contato@yunofp.com.br">contato@yunofp.com.br</a>
            </MenuWrapper>
            <MenuWrapper>
              <Title>Nossas redes sociais</Title>
              <SocialMedia>
                <BsInstagram style={{cursor: 'pointer'}} onClick={() => navigateTo('https://www.instagram.com/yuno.fp/')} />
                <BsYoutube style={{cursor: 'pointer'}} onClick={() => navigateTo('https://www.youtube.com/yunofp')} />
                <BsLinkedin style={{cursor: 'pointer'}} onClick={() => navigateTo('https://www.linkedin.com/company/yunofp/')} />
              </SocialMedia>
              <ButtonNavigateToTop
                style={{ position: 'absolute',
                          right: '-8px',
                          top: '160px' }}
              />
            </MenuWrapper>
          </ContentTop>
        </Container>
      </ContainerFull>
      <ContainerFull className="separator-bootom">
        <Container distance column justifyCenter>
          <DivContactInfos>
            <DivParagraph>
              <DocumentosRegulatorios />
            </DivParagraph>
          </DivContactInfos>
        </Container>
      </ContainerFull>
      <ContainerFull>
        <Container style={{padding: '40px 0'}} className="flex-md-row">
            <DivParagraph>
              <p>2023 © Yuno Brasil – Todos os direitos reservados</p>
            </DivParagraph>
            <LogosContainer>
              <LogoCFP />
              <LogoCVM />
            </LogosContainer>
        </Container>
      </ContainerFull>
    </ThemeProvider>
  );
};

FooterComponent.propTypes = {
  themeType: PropTypes.string,
};

FooterComponent.defaultProps = {
  themeType: 'default',
};

export default FooterComponent;
