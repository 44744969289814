import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  Home,
  SobreNos,
  // ParaEducar,
  // Sucesso,
  // TermosCondicoesDeUso,
  PoliticaPrivacidade,
  ParaAprender,
  Mandala,
  ParaVoce,
  ParaVoceGrow,
  ParaVoceWealth,
  PoliticaFamilyOffice,
  Page,
} from './pages';
import {
  Campanha,
  EscolhasViverOuInvestir,
  EscolhasViverOuInvestirSucesso,
} from './campanhas';

import { ContainerFluid } from './App.styled';

export default function Switch() {
  return (
    <ContainerFluid className="vh100">
      <Routes>
        <Route
          path="/"
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          path="/mandala"
          element={
            <Page>
              <Mandala />
            </Page>
          }
        />
        <Route
          path="/para-aprender"
          element={
            <Page>
              <ParaAprender />
            </Page>
          }
        />
        {/* <Route
          path="/sucesso"
          element={
            <Page>
              <Sucesso />
            </Page>
          }
        /> */}
        <Route
          path="/para-voce"
          element={
            <Page>
              <ParaVoce />
            </Page>
          }
        />
        <Route
          path="/para-voce/grow"
          element={
            <Page>
              <ParaVoceGrow />
            </Page>
          }
        />
        <Route
          path="/para-voce/wealth"
          element={
            <Page>
              <ParaVoceWealth />
            </Page>
          }
        />
        <Route
          path="/para-voce/family-office"
          element={
            <Page>
              <PoliticaFamilyOffice />
            </Page>
          }
        />
        <Route
          path="/sobre-nos"
          element={
            <Page>
              <SobreNos />
            </Page>
          }
        />
        <Route
          path="/politica-de-privacidade"
          element={
            <Page>
              <PoliticaPrivacidade />
            </Page>
          }
        />
        {/* <Route
          path="/termos-e-condicoes-gerais-de-uso"
          element={
            <Page>
              <TermosCondicoesDeUso />
            </Page>
          }
        />
        <Route
          path="/politica-de-privacidade"
          element={
            <Page>
              <PoliticaPrivacidade />
            </Page>
          }
        />

        <Route
          path="/para-educar"
          element={
            <Page>
              <ParaEducar />
            </Page>
          }
        /> */}
        <Route
          path="c/escolhas-viver-ou-investir"
          element={
            <Campanha>
              <EscolhasViverOuInvestir />
            </Campanha>
          }
        />
        <Route
          path="c/escolhas-viver-ou-investir-sucesso"
          element={
            <Campanha>
              <EscolhasViverOuInvestirSucesso />
            </Campanha>
          }
        />
        <Route
          path="*"
          element={
            <Page>
              <h1 style={{ textAlign: 'center', marginTop: '2rem' }}>
                Página não encontrada
              </h1>
            </Page>
          }
        />
      </Routes>
    </ContainerFluid>
  );
}
